import { Text, Flex, Box, Button, Input, TextArea } from '../primitives'
import { FC, useEffect, useState, useContext } from 'react'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { v4 as uuid } from 'uuid';
import { useWalletClient } from 'wagmi'
import { ToastContext } from '@/context/ToastContextProvider'
import { getRandom } from '../Sanity/SanityContext';
import { styled } from '@/stitches.config'


const StyledInput = styled('select', {
    width: '100%',
    px: 16,
    py: 12,
    borderRadius: 8,
    fontFamily: '$body',
    fontSize: 16,
    color: '$gray12',
    backgroundColor: '$gray3',
    $$focusColor: '$colors$primary8',
    '&::placeholder': { color: '$gray10' },
    '&:focus': { boxShadow: 'inset 0 0 0 2px $$focusColor' },
    '&:disabled': {
        backgroundColor: '$gray2',
        color: '$gray9',
    }
})
type Props = {
    isConnected: boolean,
    userId?: string
}

export const OpeTicket: FC<Props> = ({ isConnected, userId }) => {

    const { addToast } = useContext(ToastContext)
    const { data: wallet } = useWalletClient()
    const { openConnectModal } = useConnectModal()
    const [isDataValidated, setIsDataValidated] = useState<boolean>(false)
    const [isSubValidated, setIsSubValidated] = useState<boolean>(false)

    const onSubscribe = async (event: any) => {
        event.preventDefault() 
        setIsSubValidated(true)
        // Get data from the form.
        if (!wallet?.account?.address) {
            openConnectModal?.()
            return
        }
        const data = {
            _id: uuid(),
            name: event.target.name.value,
            email: event.target.email.value
        }
        // Send the data to the server in JSON format.
        const JSONdata = JSON.stringify(data)
        // API endpoint where we send form data.
        const endpoint = '/api/subscribe'

        // Form the request for sending data to the server.
        const options = {
            // The method is POST because we are sending data.
            method: 'POST',
            // Tell the server we're sending JSON.
            headers: {
                'Content-Type': 'application/json',
            },
            // Body of the request is the JSON data we created above.
            body: JSONdata,
        }
        try {
            const response = await fetch(endpoint, options)
            await response.json().then((result) => { 
                setIsSubValidated(false)
                addToast?.({
                    description: `Thank you for subscribe CoinPulse.`,
                    status: 'success',
                    title: 'Open a Ticket'
                }) 
            }).finally(() => {
                setIsSubValidated(false)
            })

        }
        catch (error: any) {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.response.data.detail ||
                error.message ||
                error.toString();  
        }
    }

    const onSubmitHandler = async (event: any) => {
       
        // Stop the form from submitting and refreshing the page.
        event.preventDefault()
        setIsDataValidated(true)
        // Get data from the form.
        if (!wallet?.account?.address) {
            openConnectModal?.()
            return
        }

        const data = {
            _id: uuid(),
            logId: uuid(),
            userId: userId,
            ticketId: 'CP-' + getRandom(6),
            name: event.target.name.value,
            email: event.target.email.value,
            subject: event.target.subject.value,
            message: event.target.message.value,
            priority: event.target.priority.value,
            status: true
        } 

        // Send the data to the server in JSON format.
        const JSONdata = JSON.stringify(data)
        // API endpoint where we send form data.
        const endpoint = '/api/createTicket'

        // Form the request for sending data to the server.
        const options = {
            // The method is POST because we are sending data.
            method: 'POST',
            // Tell the server we're sending JSON.
            headers: {
                'Content-Type': 'application/json',
            },
            // Body of the request is the JSON data we created above.
            body: JSONdata,
        }
        try {
            const response = await fetch(endpoint, options)
            await response.json().then((result) => { 
                setIsDataValidated(false)
                addToast?.({
                    description: `Your ticket successfully created ${data?.ticketId}, Our support team will get back to you ASAP via email or reply on your ticket.`,
                    status: 'success',
                    title: 'Open a Ticket'
                }) 
            }).finally(() => {
                setIsDataValidated(false)
            })

        }
        catch (error: any) {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.response.data.detail ||
                error.message ||
                error.toString();
            //console.log('hello manish')
            console.log(resMessage)
        }


    }
    return (
        <Box style={{
            zIndex: '0', display: 'grid'
        }}
            css={{
                '@sm': {
                    px: '$0',
                },
            }}

        >
            <Flex
                css={{
                    flexWrap:'wrap',
                    alignItems: 'center',
                    zIndex: '10', 
                    flexDirection: 'column',
                    gap: 36,
                    '@bp600': {
                        flexDirection: 'row',
                        gap: 0,
                    },
                }}
            >
                <Box className="w-full px-4 lg:w-7/12 xl:w-8/12">
                    <Box css={{ backgroundColor: '$gray2' }} className="wow fadeInUp rounded-md py-11 px-8 sm:p-[55px] lg:mb-5 lg:px-8 xl:p-[25px]" data-wow-delay=".15s ">
                        <Text className="mb-3 text-3xl font-bold sm:text-3xl lg:text-2xl xl:text-3xl">Need Help? Open a Ticket</Text>
                        <p className="mb-12 text-base font-medium text-body-color">Our support team will get back to you ASAP via email.</p>
                        <form onSubmit={onSubmitHandler} method='post'>
                            <Box className="-mx-4 flex flex-wrap">
                                <Box className="w-full px-4 md:w-1/2">
                                    <Box className="mb-8">
                                        <label for="name" className="mb-3 block text-sm font-medium text-dark">Your Name</label>
                                        <Input type="text" id='name' name='name' placeholder="Enter your name" required className="w-full" />
                                    </Box>
                                </Box>
                                <Box className="w-full px-4 md:w-1/2">
                                    <Box className="mb-8">
                                        <label for="email" className="mb-3 block text-sm font-medium text-dark">Your Email</label>
                                        <Input type="email" id='email' name='email' pattern='/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i' placeholder="Enter your email" required className="w-full" />
                                    </Box>
                                </Box>
                                <Box className="w-full px-4 md:w-1/2">
                                    <Box className="mb-8">
                                        <label for="subject" className="mb-3 block text-sm font-medium text-dark">Subject</label>
                                        <Input name="subject" id='subject' placeholder="Subject" required className="w-full" />
                                    </Box>
                                </Box>
                                <Box className="w-full px-4 md:w-1/2">
                                    <Box className="mb-8">
                                        <label for="priority" className="mb-3 block text-sm font-medium text-dark">Priority</label>
                                        <StyledInput
                                            name='priority'
                                            id='priority'>
                                            <option value="3">High</option>
                                            <option value="2">Medium</option>
                                            <option value="1">Low</option>
                                        </StyledInput>
                                    </Box>
                                </Box>

                                <Box className="w-full px-4">
                                    <Box className="mb-8"><label for="message" className="mb-3 block text-sm font-medium text-dark">Your Message</label>
                                        <TextArea name="message" id='message' rows="5" required placeholder="Enter your Message" className="w-full" />
                                    </Box>
                                </Box>
                                <Box className="w-full px-4">
                                    <Button type='submit' disabled={!isConnected || isDataValidated
                                    } className="rounded-md bg-primary py-4 px-9 text-base font-medium transition duration-300 ease-in-out">

                                        {isDataValidated ? (
                                            <>
                                                <svg
                                                    className="-ml-1 mr-3 h-5 w-5 animate-spin "
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth={4}
                                                    ></circle>
                                                    Submitting...
                                                    <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    ></path>
                                                </svg>
                                                Submitting...
                                            </>
                                        ) : (
                                            ' Submit Ticket'
                                        )}
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </Box>
                </Box>
                <Box className="w-full sm:mt-6 px-4 lg:w-5/12 xl:w-4/12">
                    <Box css={{ backgroundColor: '$gray2' }} className="wow fadeInUp relative z-10 rounded-md bg-primary/[3%] p-8 dark:bg-primary/10 sm:p-11 lg:p-8 xl:p-11" data-wow-delay=".2s">
                        <Text className="mb-4 text-3xl font-bold leading-tight">Subscribe to receive future updates</Text>
                        <p className="mb-11 border-b border-body-color border-opacity-25 pb-11 text-base font-medium leading-relaxed text-body-color dark:border-white dark:border-opacity-25">Put your investing ideas into action with full range of investments.</p>
                        <form onSubmit={onSubscribe} method='post'>
                            <Input type="text" name="name" required placeholder="Enter your name" className="mb-4 w-full" />
                            <Input type="email" name="email" pattern='/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i' required placeholder="Enter your email" className="mb-4 w-full" />
                            <Button type='submit' disabled={!isConnected || isSubValidated} className="rounded-md bg-primary py-4 px-9 text-base font-medium transition duration-300 ease-in-out">
                                {isSubValidated ? (
                                    <>
                                        <svg
                                            className="-ml-1 mr-3 h-5 w-5 animate-spin "
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                        >
                                            <circle
                                                className="opacity-25"
                                                cx="12"
                                                cy="12"
                                                r="10"
                                                stroke="currentColor"
                                                strokeWidth={4}
                                            ></circle>
                                            Subscribing...
                                            <path
                                                className="opacity-75"
                                                fill="currentColor"
                                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                            ></path>
                                        </svg>
                                        Subscribing...
                                    </>
                                ) : (
                                    'Subscribe'
                                )}</Button>
                            <p className="text-center text-base font-medium leading-relaxed text-body-color">No spam guaranteed, So please don’t send any spam mail.</p>
                        </form>
                    </Box>
                </Box>
            </Flex>
        </Box>
    )
}